<template>
  <div>
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section">
        <div class="panel panel-default">
          <div class="panel-body ">
            <div class=" owner-name   ">
              <span
                class="t18" style="font-weight: 600" v-html="$t('full_name_format_default', { first_name:$auth.user().first_name, last_name:$auth.user().last_name })">
              </span>

              <small class="small-text pull-right t16">
                <router-link style="color: #6092df ;" class="popup-edit-account" to="/edit-profile">{{$t('button_change')}}</router-link>
              </small>
            </div>
            <div class=" owner-email t16 cl-78838c pt-10 fw-normal ">{{ $auth.user().email}}</div>
            <div class="owner-dob pt-5 t16 cl-78838c fw-normal">
              <div v-if="birthday != '' ">
                <span v-if="!isBirthday"><img
                  src="https://deo515rx9pz7c.cloudfront.net/fcds/icons/black%403x.png" width="24"> {{birthday}} {{$t('calculate_month')}}</span>
                <span v-else><i class="fa fa-exclamation-circle"></i> {{$t('your_birth_month')}}</span>
              </div>
              <div v-else>
                <div class="mobile-only"><img
                  src="https://deo515rx9pz7c.cloudfront.net/fcds/icons/black%403x.png" width="24">
                  <router-link style="color: #6092df ;" class="popup-edit-account" to="/edit-profile"> {{$t('have_birthday')}}
                  </router-link>
                </div>
              </div>
            </div>
            <div class=" half-btn pt-5 text-center">
              <router-link style="" class="btn btn-default btn-view-saved-order t18 decoration-none" to="/history"> {{$t('btn_view_saved_orders')}}
              </router-link>
            </div>
          </div>
        </div>
        <div class="account-details">
          <div class="panel panel-default">
            <div class="panel-body t15">
              <h2 class="text-center t18 bold heading-line">{{$t('label_preferred_payment')}}</h2>
              <div class="checkbox-form">
                <label class="items-center fw400 cursor-pointer" for="payment_method4">
                  <input id="payment_method4" class="radio" type="radio" name="payment_method" v-bind:value="$const.PAYMENT_METHOD_CASH_ON_DELIVERY" v-model="paymentMethod">
                  <i class="pull-left xs-my-5"></i>
                  <span class="mr-10"> {{$t('cash')}}</span>
                  <img class="payment-icon" src="https://static.phdvasia.com/pe/cash.png" width="24">
                </label>
              </div>
              <div class="checkbox-form">
                <label class="items-center fw400 cursor-pointer" for="payment_method2">
                  <input id="payment_method2" class="radio" type="radio" name="payment_method" v-bind:value="$const.PAYMENT_METHOD_CREDIT_ON_DELIVERY" v-model="paymentMethod">
                  <i class="pull-left xs-my-5"></i>
                  <span class="mr-10"> {{$t('card.cash')}}</span>
                  <img class="payment-icon" src="https://static.phdvasia.com/mx/icons/credit-cards.png">
                </label>
              </div>
              <div class="error">
                <span v-if="errors.paymentMethod" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                    <span>{{ errors.paymentMethod }}</span>
                </span>
              </div>
              <div class="  pt-5 text-center">
                <button type="button" @click="savePayment()" class="btn btn-success btn-save-payment-method t18" style="width: 100%">{{$t('btn_save')}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-body t15">
            <h2 class="text-center t18 bold heading-line">{{$t('saved_location')}}</h2>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="(item, index)  in saved_locations">
                <span class="t18 fw600 mr-5"> {{ item.name || item.location }}</span>
                <button type="button" class="btn btn-warning btn-xs mr-5" v-if="item.default_location ===1">{{$t('btn_default')}}</button>
                <router-link style="color: #6092df ;" :to="{ name: 'add-location', params: { 'id' : item.id }}">{{$t('btn_edit')}}
                </router-link> |
                <a style="color: #6092df;" @click="removeLocation(item,index)" href="javascript:void(0)"> {{$t('btn_delete')}}</a>
                <br />
                {{ item.display_address || item.location }}
              </li>
            </ul>
            <div class="  pt-5 text-center">
              <router-link class="btn btn-success btn-add-location t18" style="width: 100%" :to="{ name: 'add-location'}">{{$t('btn_add_location')}}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {
    moment
  },
  watch: {
    paymentMethod: function (newValue, oldValue) {
      this.errors.paymentMethod = ''
      if (this.paymentMethod === '') {
        this.errors.paymentMethod = 'PaymentMethod required.'
      }
    }
  },
  mounted: function () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'user.profile', 'type': 'user'}})

    let info = this.$auth.user()
    if (info.prefer_payment) {
      this.paymentMethod = info.prefer_payment
    }
    if (info.saved_locations) {
      this.saved_locations = info.saved_locations
    }

    if (info.birthdate) {
      var birthday = moment(info.birthdate);
      var today = moment().format("YYYY-MM-DD")
  
      var age = moment(today).diff(birthday, 'years')
      moment(age).format("YYYY-MM-DD")
  
      var nextBirthday = moment(birthday).add(age, 'years')
      moment(nextBirthday).format("YYYY-MM-DD")
  
      if (nextBirthday.isSame(today)) {
        this.birthday = this.$t('happy_birthday')
      } else {
        nextBirthday = moment(birthday).add(age + 1, 'years')
        this.birthday = nextBirthday.diff(today, 'months')
      }
    }
    this.getLocation()
    // this.getPaymentMethodList()
  },
  data () {
    return {
      paymentMethodList: [],
      birthday: '',
      isBirthday: false,
      paymentMethod: this.$const.PAYMENT_METHOD_CASH_ON_DELIVERY, // set default payment method for new user
      errors: {
        'paymentMethod': ''
      },
      saved_locations: []
    }
  },
  methods: {
    /**
     * get Payment Method
     * */
    // getPaymentMethodList () {
    //   var vm = this
    //   this.axios.apiTenant.getPaymentMethod(function (response) {
    //     if (response.data.data.items) {
    //       if (response.status ===200) {
    //         vm.paymentMethodList = response.data.data.items
    //       }
    //     }
    //   })
    // },
    savePayment: function () {
      let vm = this
      this.errors.paymentMethod = ''
      if (this.paymentMethod === '') {
        this.errors.paymentMethod = 'PaymentMethod required.'
        return false
      }
      let user = this.$auth.user()
      user.prefer_payment = this.paymentMethod
      this.axios.apiCustomer.updateProfile(user, (response) => {
        vm.$auth.token('default_auth_token', btoa(escape(JSON.stringify(user))))
        vm.$auth.user(user)
        vm.$notify({
          type: 'success',
          title: vm.$t('noti_title_update_success')
        })
      })
    },
    removeLocation: function (item, id) {
      let vm = this
      this.axios.apiCustomer.deleteLocation(item.id, (response) => {
        let info = vm.$auth.user()
        info.locations.splice(id, 1)
        vm.saved_locations.splice(id, 1)
        vm.$auth.token('default_auth_token', btoa(escape(JSON.stringify(info))))
        vm.$auth.user(info)
        vm.$notify({
          type: 'success',
          title: vm.$t('noti_title_delete_success')
        })
      })
    },
    getLocation: function () {
      for (let i in this.$auth.user().locations) {
        this.saved_locations.push(this.$auth.user().locations[i])
      }
    }
  }
}

</script>
<style scoped>
  .form-section {
    padding: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
  }

</style>
